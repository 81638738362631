export const patientSTGT: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO - Retina Spain",
  banner: "Banner HTML - STGT - DTC - Retina Spain",
  footer: "Footer Consumer - Retina Spain",
  exitPopup: "Exit Popup - DTC - Retina Spain",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  topNav: "Top Navigational Menu Consumer - Retina Spain",
  siteLogo: "Site logos - Retina Spain",
  footerRef: "Reference - STGT - DTC - Retina Spain",
  tabTitle: "Tabbed content - STGT - DTC - Retina Spain",
  backToTopGTM: "back to top - steps to genetic testing",
  tabAttrs: {
    stepContent: 'Paso',
    tabGTMLabels : [{
      tabButtonGTM: {
        'data-gtm-event-label': 'steps to genetic testing tab',
        'className': 'gtm-accordion'
      },
      slidersGTM: [
        {
          iconGTMs: [
            {
              'data-gtm-event-label': 'step 1 - schedule an exam',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 2 - meet with an genetic counselor',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 3 - genetic testing will be ordered',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 4 - discuss the results',
              'className': 'gtm-accordion'
            }
          ],
          prevBtnGTMs: [
            {
              'data-gtm-event-label': 'step 1 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 1'
            },
            {
              'data-gtm-event-label': 'step 2 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 2'
            },
            {
              'data-gtm-event-label': 'step 3 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 3'
            },
            {
              'data-gtm-event-label': 'step 4 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 4'
            }
          ],
          nextBtnGTMs: [
            {
              'data-gtm-event-label': 'step 1 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 1'
            },
            {
              'data-gtm-event-label': 'step 2 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 2'
            },
            {
              'data-gtm-event-label': 'step 3 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 3'
            },
            {
              'data-gtm-event-label': 'step 4 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 4'
            }
          ]
        }
      ]
    },
    {
      tabButtonGTM: {
        'data-gtm-event-label': 'understanding results tab',
        'className': 'gtm-accordion'
      },
      slidersGTM: [{}]
    }
  ]},
  footerClassName: "steps-to-genetic-testing-footer"
}
